import Image from 'next/legacy/image'
import styled from 'styled-components'

import {
    JotaMaterialMenu as MenuIcon,
    tokens
} from '@JOTAJornalismo/jota-design-system'
import { LAYOUT_CONSTANT } from '../../utils/constants'

export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

const { spaces, colors, fonts, borders, shadows } = tokens

const {
    jotaSpacing12,
    jotaSpacing16,
    jotaSpacing24,
    jotaSpacing36,
    jotaSpacing44
} = spaces

const { transparent, white, black, gray, orange } = colors

export const HeaderContainer = styled.div<{
    scroll?: boolean
    hasEditorAccess: boolean
}>`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${transparent.jotaTGrayLight40};
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    background-color: ${white.jotaWhite};
    ${(props) => props.scroll && `position: fixed;`};
    ${(props) => props.hasEditorAccess && `margin-top: 40px;`};
    z-index: 2;
`

export const HeaderContainerBase = styled.div<{
    pageTributos: boolean
    scroll?: boolean
}>`
    display: flex;
    justify-content: space-between;
    padding: ${jotaSpacing12} ${jotaSpacing44} ${jotaSpacing12} 32px;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
    ${(props) => props.scroll && `height: 54px;`};

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        padding: ${jotaSpacing12} ${jotaSpacing36};
        display: ${(props) => (!props.pageTributos ? 'none' : 'inherit')};
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: ${jotaSpacing12} ${jotaSpacing16};
        display: ${(props) => (!props.pageTributos ? 'none' : 'inherit')};
    }
`

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${SM_PX}) {
        & div {
        }
    }
`
export const Accessibility = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg:first-of-type {
        padding-right: 8px;
    }

    svg:nth-child(3) {
        padding-left: 8px;
    }

    svg:last-of-type {
        padding-left: ${jotaSpacing16};
    }

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        display: none;
    }

    @media only screen and (max-width: ${SM_PX}) {
        display: none;
    }
`

export const Article = styled.article`
    background-color: #000000;
    ${fonts.jotaBody};
    color: #d3d3d9;
    padding: 8px ${jotaSpacing44};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    white-space: nowrap;

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        padding: 8px ${jotaSpacing36};
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 8px ${jotaSpacing16};
    }
`
export const Menu = styled.div`
    display: none;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        display: flex;
        background: none;
        border: none;
    }

    @media only screen and (max-width: ${SM_PX}) {
        display: flex;
        background: none;
        border: none;
    }
`
export const HamburguerMenuContainer = styled.div<{ showHamburguer: boolean }>`
    display: none;

    ${(props) =>
        props.showHamburguer &&
        `   display: flex;
        `};
`

export const LoggedOutButtons = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`

export const LoggedMenu = styled.div<{ isHeader: boolean }>`
    display: ${(props) => (props.isHeader ? 'none' : 'inherit')};
    position: relative;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        display: ${(props) => (props.isHeader ? 'block' : 'inherit')};
    }
`
export const UserMenuContainer = styled.div<{ showUser: boolean }>`
    display: none;

    ${(props) =>
        props.showUser &&
        `   display: flex;
        `};
`

export const Text = styled.p`
    text-align: center;
    margin: 0;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        display: none;
    }

    @media only screen and (max-width: ${SM_PX}) {
        display: none;
    }
`

export const TextMobileCTAPRO = styled.div`
    color: ${colors.gray.jotaGrayLight};
    width: 100%;
    a {
        color: ${colors.white.jotaWhite};
        text-decoration: underline;
        font-weight: 700;
    }
    @media screen and (min-width: 1200px) {
        display: none;
    }

    @media screen and (max-width: 1200px) {
        display: flex;
        text-align: center;
        margin: 0;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
`

export const TextDesktopCTAPRO = styled.div`
    color: ${colors.gray.jotaGrayLight};
    a {
        color: ${colors.white.jotaWhite};
        text-decoration: underline;
        font-weight: 700;
    }
    @media only screen and (max-width: 1200px) {
        display: none;
    }
    @media only screen and (min-width: 1200px) {
        display: flex;
        text-align: center;
        margin: 0;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
    }
`

export const ImgContainer = styled(Image)`
    margin-right: ${jotaSpacing24} !important;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        margin-inline: ${jotaSpacing12};
    }

    @media only screen and (max-width: ${SM_PX}) {
        margin-left: ${jotaSpacing12};
    }
`

export const LogoContainer = styled.div`
    margin-right: ${tokens.spaces.jotaSpacing24};
`

export const CloseIconContainer = styled.div`
    justify-self: flex-end;
    align-self: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
`

export const Link = styled.a`
    text-decoration: underline;
    font-weight: 700;
`

export const UserMenuDiv = styled.div<{ isHeader: boolean }>`
    background-color: ${white.jotaWhite};
    border: 1px solid ${transparent.jotaTGrayLight40};
    ${shadows.jotaShadowDefault()};
    position: absolute;
    right: -8px;
    top: ${(props) => (props.isHeader ? 'inherit' : '44px')};
    z-index: 9999;
    border-radius: 0 0 ${borders.jotaRadiusDefault} ${borders.jotaRadiusDefault};
    width: 300px;

    &:before {
        content: '';
        position: absolute;
        width: ${jotaSpacing16};
        height: ${jotaSpacing16};
        top: -4px;
        right: 12px;
        background-color: #ffffff;
        transform: rotate(45deg);
    }
`
export const UserHeader = styled.div`
    padding: ${jotaSpacing12} 0 ${jotaSpacing12} ${jotaSpacing12};
`
export const UserNameContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 1px 0;
`
export const UserName = styled.p`
    ${fonts.jotaBody()};
    font-weight: 700;
    color: ${black.jotaBlackLight};
    margin: 0 6px 0 0;
`
export const UserEmail = styled.p`
    ${fonts.jotaBodyXSmall()};
    color: ${gray.jotaGrayDark};
    margin: 1px 0 0 0;
`

export const NavBar = styled.nav<{ scroll?: boolean }>`
    border-top: 1px solid ${transparent.jotaTGrayLight40};
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-inline: 32px ${jotaSpacing44};
    ${(props) => props.scroll && `display: none;`}
`

export const HeaderDesktopContainer = styled.div`
    background: ${colors.white.jotaWhite};
    width: 100%;
    z-index: 99;
    @media only screen and (max-width: ${MD_PX}) {
        display: none;
    }
`
export const HeaderMobileContainer = styled.div<{ logged?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-content: space-between;
    padding: ${spaces.jotaSpacing12} ${spaces.jotaSpacing36};
    transition: all 0.5s;

    @media only screen and (min-width: ${MD_PX}) {
        display: none;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding-block: ${spaces.jotaSpacing4};
        padding-right: ${spaces.jotaSpacing16};
        padding-left: ${(props) =>
            props.logged ? spaces.jotaSpacing6 : spaces.jotaSpacing16};
    }
`

export const HeaderMobileLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const HeaderMobileRight = styled.div``

export const HamburguerContainer = styled.div`
    background: none;
`

export const MenuButton = styled.div`
    &:hover {
        cursor: pointer;
    }
`

export const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
`

export const Buttons = styled.div<{ isHeader: boolean }>`
    display: ${(props) => (props.isHeader ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;

    button:first-of-type {
        margin: 0px ${jotaSpacing12} 0px 0px;
    }

    button:last-of-type {
        margin: 0px 0px 0px ${jotaSpacing12};
    }
`
export const List = styled.div`
    display: flex;
    gap: 6px;

    div {
        & div {
            display: flex;
            /* background-color: blue; */
        }
    }
`

export const Jota = styled.picture<{ scroll: boolean }>`
    display: none;

    ${(props) =>
        props.scroll &&
        `display: flex;  
        padding-left: 6px;
        `};
`

export const MagnifingGlass = styled.picture<{ scroll: boolean }>`
    display: none;

    ${(props) =>
        props.scroll &&
        `display: flex;  
        margin: 0px;
        padding: 0px;
        `};
`

export const UserMenuContainerEditorial = styled.div<{ showUser: boolean }>`
    display: none;

    ${(props) =>
        props.showUser &&
        `   display: flex;
        `};
`
export const HamburguerMenuContainerEditorial = styled.div<{
    showHamburguer: boolean
}>`
    display: none;

    ${(props) =>
        props.showHamburguer &&
        `   display: flex;
        `};
`
export const LogoCompactContainer = styled.div`
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 6px;
    cursor: pointer;
`
export const MenuHamburguerComponent = styled.div<{
    height: string
    scrollPosition: any
}>`
    max-width: 300px;
    background-color: ${white.jotaWhite};
    border-radius: 0 0 ${borders.jotaRadiusDefault} ${borders.jotaRadiusDefault};
    ${shadows.jotaShadowDefault()}
    position: fixed;
    top: 0;
    overflow-y: auto;
    height: ${(props) => props.height};
    scrollbar-color: ${colors.transparent.jotaTGray50} transparent;
    scrollbar-width: thin;
    z-index: ${(props) => props.scrollPosition > 0 && '99'};
`
export const HeaderHamburguer = styled.div`
    padding: 8px ${jotaSpacing16};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${transparent.jotaTGrayLight40};
`
export const InputContainerHamburguer = styled.div`
    padding: ${jotaSpacing16};
    display: none;

    @media only screen and (max-width: ${SM_PX}) {
        display: block;
    }
`
export const ProItemsContainerHamburguer = styled.div`
    background-color: ${transparent.jotaTGrayLighter40};
`
export const ButtonsContainerHamburguer = styled.div`
    display: grid;
    gap: ${jotaSpacing16};
    padding: ${jotaSpacing16} ${jotaSpacing16} ${jotaSpacing24} ${jotaSpacing16};
`
export const MenuIconHover = styled(MenuIcon)`
    &:hover {
        cursor: pointer;
        fill: ${orange.jotaOrange};
    }

    margin-right: 14px;

    @media only screen and (max-width: ${MD_PX}) {
        margin: ${spaces.jotaSpacing6};
        margin-right: ${spaces.jotaSpacing12};
    }
`
export const SubMenuHamburguer = styled.div``
export const SubMenuHamburguerContainer = styled.div``
export const SubMenuHeader = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    background: white;
    display: none;
    margin: 0;
    box-shadow: 0px 0px 24px rgba(31, 33, 40, 0.12);
    z-index: 999;
    border: 1px solid ${transparent.jotaTGrayLight40};
    border-radius: 0px 0px 4px 4px;
    /* max-width: 300px;
    width: 100%; */
`
export const SubMenuColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 12px 0;
    width: 260px;
`

export const SubMenuColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 260px;
    border-left: 1px solid ${transparent.jotaTGrayLight40};
`
export const SubMenuEditorial = styled.div`
    & > ${SubMenuHeader} {
        padding: 4px 0 0 0;
        margin: 0;
        display: none;

        & > div {
            // margin: 0;
        }
    }
`

export const SearchCompactContainer = styled.div<{ onlyMobile: boolean }>`
    background: none;
    border: none;
    display: ${(props) => (props.onlyMobile ? 'none' : 'block')};

    @media only screen and (max-width: ${MD_PX}) {
        display: ${(props) => (props.onlyMobile ? 'flex' : 'block')};
    }

    @media only screen and (max-width: ${SM_PX}) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
`

export const SearchCompact = styled.div`
    background: ${gray.jotaGrayLighter};
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${transparent.jotaTGrayLight40};
    height: 56px;
`
export const SearchCenter = styled.div`
    width: 400px;
`
export const ContainerNavBar = styled.div`
    transition: all 0.35s linear;
`
export const AnimationBlock = styled.div`
    transition: all 0.5s;
`

export const Body = styled.div`
    max-width: ${LG_PX};
    width: 100%;
    border-left: 1px solid ${transparent.jotaTGrayLight40};
    border-right: 1px solid ${transparent.jotaTGrayLight40};
    position: relative;

    @media only screen and (max-width: ${LG_PX}) {
        border: none;
    }
`

export const FirstScroll = styled.div`
    display: grid;
    gap: ${jotaSpacing24};
    grid-template-columns: 40.667% auto 22.223%;
    padding: 0 ${jotaSpacing44};
    padding-top: 44px;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        grid-template-columns: none;
        gap: ${jotaSpacing44};
        padding: ${jotaSpacing36};
    }

    @media only screen and (max-width: ${SM_PX}) {
        grid-template-columns: none;
        gap: ${jotaSpacing44};
        padding: ${jotaSpacing44} ${jotaSpacing16};
    }
`

export const VerticaisContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${jotaSpacing44};
    padding: 0 ${jotaSpacing44} ${jotaSpacing44} ${jotaSpacing44};

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        flex-direction: column;
        gap: 88px;
        padding: 0 ${jotaSpacing36} ${jotaSpacing36} ${jotaSpacing36};
        display: flex;
    }

    @media only screen and (max-width: ${SM_PX}) {
        flex-direction: column;
        gap: 88px;
        padding: 0 ${jotaSpacing16} ${jotaSpacing16} ${jotaSpacing16};
        display: flex;
    }
`
export const InputSearch = styled.div``

export const InputSearchContainer = styled.div`
    & > ${InputSearch} {
        @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
            display: none;
        }
    }
`
export const ContainerLinkCTA = styled.div``

export const UserContainerItem = styled.div``
export const AccordionContainer = styled.div``
export const AccordionChildContainer = styled.div``
export const CTAContainer = styled.div<{ scroll?: boolean }>`
    background: ${colors.black.jotaBlack};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    justify-content: center;
    ${(props) => props.scroll && `display: none;`}
`

export const ButtonContainer = styled.div`
    padding: 16px 16px 12px 18px;
`
